import request from './request'
import { QueryPageDto, WorkerDto } from './dto'

// 案件列表
export function getReportLlist (
    params: QueryPageDto
): Promise<{ data: WorkerDto[]; total: number }> {
    return request.get('/report/list', {
        params
    })
}

export function getPolicyPersonList (
    params: any
): Promise<{ data: WorkerDto[]; total: number }> {
    return request.get('/policy.person/list', {
        params
    })
}

interface addReportReqDto {
  employeeId: string
  employeePhone: string
  caseDate: string
  type: string
  address: string
  desc: string
  fileIds: string[]
  billFileIds: string[]
  reporterPhone: string
}

export function addPeport (data: addReportReqDto): Promise<void> {
    return request.post('/report/add', data)
}

// 撤销报案
export function cancelReport (data: { reportId: string }): Promise<void> {
    return request.post('/report/cancel', data)
}

export interface BillDto {
  id: number
  type: string
  url: string
}
// 案件详情
export interface ReportDetailDto {
  address: string
  hospital: string
  case_date: string
  create_time: string
  bill_formatted: {
    type: string,
    max_num: number,
    remark: string,
    is_required: number,
    files: BillDto[]
  }[]
  desc: string
  employee: { employee_id: number; name: string; idcard: string }
  employee_id: number
  employee_phone: string
  estimate_money: string
  final_money: string
  is_serious: number
  is_hospital: number
  money_shangcan: string
  money_wugong: string
  money_yiliao: string
  policyPerson: {
    occupation_name: string
    occupation_level: string
    start_date: string
    policy: {
      plan: {
        name: string
      }
    }
    company_name: string
  }
  file: {
    url: string
  }[]
  policy_person_id: number
  remark: string
  report_id: number
  reporter_phone: string
  status: string
  trace: {
    trace_date: string
    title: string
    content: string
  }[]
  type: string
}

export function getReportDetail (params: {
  reportId: string
}): Promise<ReportDetailDto> {
    return request.get('/report/detail', {
        params
    })
}

export interface AddBillDto {
  reportId: number
  fileId: string
  type: string
}

export function addBill (params: AddBillDto): Promise<void> {
    return request.post('/report/addBill', params)
}

// 获取单据上传的配置项
export function delBill (params: { id: number }): Promise<void> {
    return request.post('/report/removeBill', params)
}
