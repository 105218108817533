
import { defineComponent } from 'vue'
import { WorkerDto } from '@/api/dto'
import { getReportLlist, cancelReport } from '@/api/report'
import router from '@/router'
import { getAppName } from '@/api/base'

export default defineComponent({
    name: 'Report',
    components: {},
    data () {
        return {
            searchValue: '',
            searchText: '',
            pageNum: 1,
            pageSize: 10,
            payDateSolt: '',
            total: 0,
            tableData: [] as WorkerDto[],
            showWarning: false
        }
    },
    created () {
        this.handleCurrentChange()
    },
    methods: {
        async handleCurrentChange () {
            const result = await getReportLlist({
                search: this.searchValue,
                page: this.pageNum,
                pageSize: this.pageSize
            })
            console.log(result, 'result')
            this.total = result.total
            this.tableData = result.data
        },
        // 切换页码
        handleSizeChange () {
            this.pageNum = 1
            this.handleCurrentChange()
        },
        // 搜索
        search () {
            this.pageNum = 1
            this.handleCurrentChange()
        },
        // 重置
        onReset () {
            this.searchValue = ''
            this.pageNum = 1
            this.handleCurrentChange()
        },
        onAdd () {
            this.showWarning = true
        },
        // 撤销报案
        async revoke (id: string) {
            await this.$util.confirm('撤销报案', '是否确认撤销该条报案？')
            await cancelReport({
                reportId: id
            })
            this.$message.success('撤销成功！')
            this.handleCurrentChange()
        }
    }
})
